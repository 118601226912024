<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">{{title}}</h3>
      </div>

      <div class="dashhead-toolbar">
        <div class=" dashhead-toolbar-item">
  
        </div>
      </div>
    </div>

    <hr class="my-3">

    <ListCategory
      :unitType="unitType"
      :templateType="templateType"
      :group="group">
    </ListCategory>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import ListCategory from './ListCategory.vue'

export default {
  mixins: [retainMixin],
  metaInfo () {
    return {
      title: `${this.subTitle}-${this.title}`
    }
  },
  props: {
    templateType: {
      type: String,
      required: true
    },
    unitType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      rKey: `ChronowoodthUnit${this.$form.capitalize(this.unitType)}List`,
    }
  },
  components: {
    ListCategory,
  }
}
</script>

<style lang="css" scoped>
</style>
