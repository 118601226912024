import gql from 'graphql-tag'


const listResponse = `
  id type name
`

export const LIST_UNIT = (templateType) => gql`query LIST_UNIT ($unitType: String!, $q: ${templateType}FilterInput) {
  units: list${templateType} (unitType: $unitType, q: $q) {${listResponse}}
}`

const detailResponse = `
  id type name
`

export const DETAIL_UNIT = (templateType) => gql`query DETAIL_UNIT ($unitType: String!, $unitId: Int!) {
  unit: detail${templateType} (unitType: $unitType, unitId: $unitId) {${detailResponse}}
}`

export const CREATE_UNIT = (templateType) => gql`mutation CREATE_UNIT ($unitType: String!, $input: ${templateType}CreateInput!) {
  createUnit: create${templateType} (unitType: $unitType, input: $input) {${detailResponse}}
}`

export const UPDATE_UNIT = (templateType) => gql`mutation UPDATE_UNIT ($unitType: String!, $unitId: Int!, $input: ${templateType}UpdateInput!) {
  updateUnit: update${templateType} (unitType: $unitType, unitId: $unitId, input: $input) {${detailResponse}}
}`

export const DESTROY_UNIT = (templateType) => gql`mutation DESTROY_UNIT ($unitType: String!, $unitId: Int!) {
  destroyUnit: destroy${templateType} (unitType: $unitType, unitId: $unitId) {id}
}`